//
//
//
//
//
//
//
//
//
//

import ProfileModal from '~/components/UI/Modal/ProfileModal.vue'
export default {
  name: 'ModalRoom',
  components: { ProfileModal },
  props: {},
  data() {
    return {
      error: '',
    }
  },
  computed: {},
  methods: {

    onOpen(value) {
      this.error = value
      this.$refs.modalRoom.onOpen()
    },
    confirm() {
      this.$emit('click', this.error)
      this.$refs.modalRoom.onCancel()
    },
  },
}
