//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ModalOverlay from '~/components/UI/Modal/ModalOverlay.vue'
import ProductPrice from '~/components/UI/ProductPrice.vue'
export default {
  name: 'ProductVariationsModal',
  components: {
    ModalOverlay,
    ProductPrice,
  },
  props: {
    delivery: {
      type: Number,
      default: null,
    },
    toPayment: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      modal: false,
      products: [],
    }
  },
  methods: {
    onClose() {
      this.modal = false
    },

    onOpen(value) {
      this.modal = true
      this.products = value
    },

    handleRoute() {
      this.onClose()
    },
  },
}
