//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Login',
  data() {
    return {
      loading: false,
      form: {
        email: '',
        password: '',
      },
    }
  },
  methods: {
    onSave() {
      return this.form
    },
    clearForm() {
      this.form = {
        email: '',
        password: '',
      }
    },
  },
}
