//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Login from './Login.vue'
import Register from './Register.vue'
import Modal from '~/components/UI/Modal/Modal.vue'
import { SET_TOKEN } from '~/store/auth/types.auth'
export default {
  name: 'AuthModal',
  components: { Modal, Login, Register },
  data() {
    return {
      type: '',
      loading: false,
    }
  },
  computed: {
    isLogin() {
      return this.type === 'login'
    },
    isRegister() {
      return this.type === 'register'
    },
  },
  methods: {
    onLogin() {
      const params = this.$refs[this.type].onSave()
      this.loading = true
      this.$api.auth
        .login(params)
        .then((payload) => {
          this.$refs.modal.onClose()
          const { token } = payload.data
          this.$store.commit(`auth/${SET_TOKEN}`, { token })
          this.$refs[this.type].clearForm()
        })
        .then(() => {
          this.$nuxt.refresh()
        })
        .finally(() => {
          this.loading = false
        })
    },
    onRegister() {
      const params = this.$refs[this.type].onSave()
      this.loading = true
      this.$api.auth
        .register(params)
        .then(() => {
          this.$refs.modal.onClose()
          this.$refs[this.type].clearForm()
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleClick(type) {
      this.type = type
      this.$refs.modal.onOpen()
    },
  },
}
